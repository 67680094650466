import EventDispatcher from './event_dispatcher'

let getBootstrapClassForType = (type) => {
  switch(type) {
    case 'success':
      return 'alert-success';
    case 'error':
      return 'alert-danger';
    case 'alert':
      return 'alert-warning';
    case 'notice':
      return 'alert-info';
  }
}

let getIconForType = (type) => {
  switch (type) {
    case 'success':
      return 'fa-sharp fa-light fa-check';
    case 'error':
      return 'fa-sharp fa-light fa-check';
    case 'alert':
      return 'fa-sharp fa-light fa-circle-exclamation';
    case 'notice':
      return 'fa-sharp fa-light fa-check';
  }
}


class FlashMessage extends EventDispatcher {
  constructor(type) {
    super();
    this.type = type;
    this.el = $('<div>').addClass('alert alert-closeable').addClass(getBootstrapClassForType(type));
    this.closeButton = $('<a>').addClass('close-button').html("<i class='fa-sharp fa-light fa-xmark'></i>");
    this.el.append(this.closeButton)

    this.closeButton.on('click', this.hide.bind(this));
    let contentHolder = $('<div>').addClass('content')
    contentHolder.append($('<i>').addClass('fa').addClass(getIconForType(type)));
    this.content = $('<span>').text(type);

    contentHolder.append(this.content);
    this.el.append(contentHolder);
    this.el.hide();
  }

  setMessage(message) {
    this.content.html(message);
  }

  show() {
    this.trigger('shown');
    this.el.addClass('alert-visible');
    this.el.fadeIn();
    if (this.type == 'success' || this.type == 'notice' || this.type == 'warning') {
      setTimeout(this.hide.bind(this), 3000);
    }
  }

  hide() {
    this.el.removeClass('alert-visible');
    this.el.fadeOut("slow", function () {
      this.trigger('hidden');
    }.bind(this))
  }

}

class FlashMessagesPanel {
  types = ['success', 'error', 'warning', 'notice', 'alert'];

  constructor(el) {
    if(el.length == 0)
      console.warn("[FlashMessagesPanel] No element provided.");
    this.el = el;
    this.flashMessages = {};
    
    // @todo check if 'alert' this is the same as notice
    this.types.forEach((type) => this.addFlashMessage(type));

    this.showInitialFlashMessages();
  }
 
  addFlashMessage(type) {
    let flashMessage = new FlashMessage(type);
    flashMessage.bind('shown', function() {
      this.el.addClass('active-alerts');
    }.bind(this))
    flashMessage.bind('hidden', function() {
      this.el.removeClass('active-alerts');
    }.bind(this))
    this.el.append(flashMessage.el);
    this.flashMessages[type] = flashMessage;
  }

  showFlashMessage(type, message) {
    this.flashMessages[type].setMessage(message);
    this.flashMessages[type].show();
  }

  showInitialFlashMessages() {
    const initialFlashes = this.el.data('initialFlash');

    if (!Array.isArray(initialFlashes) || initialFlashes.length === 0) {
      return;
    }

    const filteredFlashes = initialFlashes.filter(flash => this.types.includes(flash[0]));

    filteredFlashes.forEach(function(flash) {
      this.showFlashMessage(flash[0], flash[1]);
    }.bind(this));
  }
}
export default FlashMessagesPanel;
