import FlashMessagesPanel from './components/flash_messages_panel';
window.FlashMessagesPanel = FlashMessagesPanel;

var flash_messages_panel;

$(document).on('turbolinks:load', function () {
  flash_messages_panel = new FlashMessagesPanel($('.flash-messages-holder'));

  const flashData = JSON.parse(sessionStorage.getItem('flashMessage'));
  if (flashData) {
    window.showFlashMessage(flashData.type, flashData.msg);
    sessionStorage.removeItem('flashMessage'); // Remove after displaying
  }
});

$(document).ajaxComplete(function (event, request) {
  const msg = request.getResponseHeader('X-Message');
  const type = request.getResponseHeader('X-Message-Type');

  if (msg && type) {
    sessionStorage.setItem('flashMessage', JSON.stringify({ msg, type }));
  }

  if (msg)
    window.showFlashMessage(type, msg);
});

// public function outside this class defined on window
window.showFlashMessage = (type, message) => {
  flash_messages_panel.showFlashMessage(type, message);
}
